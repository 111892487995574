import FooterFlagsGC from '@skyscanner-internal/global-components/footer-flags';
import logger from 'saddlebag-logger';

import withErrorBoundary from '../ErrorBoundary/WithErrorBoundary';

import type { FooterFlagsProps } from '@skyscanner-internal/falcon-shared-types/types/FooterFlags';
import type { FooterFlagsProps as FooterFlagsGCProps } from '@skyscanner-internal/global-components/footer-flags';

const FooterFlags = (props: FooterFlagsProps) => {
  const footerFlagsGCProps: FooterFlagsGCProps = {
    ...props,
    logger,
  };
  return <FooterFlagsGC {...footerFlagsGCProps} />;
};
const FooterFlagsWithErrorBoundary = withErrorBoundary(
  FooterFlags,
  'footerFlags',
);

export default FooterFlagsWithErrorBoundary;
